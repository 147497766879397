
interface Types {
  apiBaseUrl: string;
  apiSecretKey: string;
  apiAuthKey: string;
  token: string;
  aesSecret: string;
}

let apiBaseUrl;
let apiSecretKey;
let apiAuthKey;

const AES_SECRET = '4X2FVG9wYKWxm0TvlUd8v8Nl1UU3lVyK';

const hostname = window && window.location && window.location.hostname;

if (
  hostname === "tcms-dev.telsma.com" ||
  hostname === "admin.tcms-dev.telsma.com"
) {
  apiBaseUrl = "https://api.tcms-dev.telsma.com";
  apiSecretKey = "C3BD24CC6D6CD9E02B2E6C96F0A23A62";
  apiAuthKey = "640FC9DC288DDF301D8F6120A8FA98D2";
} else {
  apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "https://api.tcms-dev.telsma.com";
  apiSecretKey = "C3BD24CC6D6CD9E02B2E6C96F0A23A62";
  apiAuthKey = "640FC9DC288DDF301D8F6120A8FA98D2";
}

const CONFIG: Types = {
  apiBaseUrl: apiBaseUrl,
  apiSecretKey: apiSecretKey,
  apiAuthKey: apiAuthKey,
  token: localStorage.getItem("token"),
  aesSecret: AES_SECRET
};

export default CONFIG;
import MDAvatar from "components/MDAvatar";
import React from "react";
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Classifications } from "lib/constants";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import { ClientRoles, StaffUserRoles } from "types/client";
import SchoolIcon from '@mui/icons-material/School';

const Login = React.lazy(() => import("page/Auth/Login"));
const Logout = React.lazy(() => import("page/Auth/Logout"));
const ResetPassword = React.lazy(() => import("page/Auth/ResetPassword"));
const ChangePassword = React.lazy(() => import("page/Auth/ChangePassword"));

const Analytics = React.lazy(() => import("page/Dashboard/Analytics"));

const FFS = React.lazy(() => import("page/Scheduling/FFS"));
const Scheduling = React.lazy(() => import("page/Scheduling/Others"));

const UpcomingTraining = React.lazy(() => import("page/Training/UpcomingTraining"));
const CompletedTraining = React.lazy(() => import("page/Training/CompletedTraining"));

const Staff = React.lazy(() => import("page/Admin/Staff/Staff"));
const Client = React.lazy(() => import("page/Admin/Client/Clients"));
const NewEditClient = React.lazy(() => import("page/Admin/Client/NewEdit"));
const ClientUsers = React.lazy(() => import("page/Admin/Client/User/Users"));
const TrainingType = React.lazy(() => import("page/Admin/Client/TrainingType"));
const ClientNewEditUser = React.lazy(() => import("page/Admin/Client/User/NewEdit"));
const ClientViewUser = React.lazy(() => import("page/Admin/Client/User/View"));
const Device = React.lazy(() => import("page/Admin/Device/Device"));
const NewEditDevice = React.lazy(() => import("page/Admin/Device/NewEdit"));
const ViewDevice = React.lazy(() => import("page/Admin/Device/View"));
const Facility = React.lazy(() => import("page/Admin/Facility/Facility"));

const DeviceReport = React.lazy(() => import("page/Report/DeviceReport"));
const FacilityReport = React.lazy(() => import("page/Report/FacilityReport"));

const UserName = () => {
  const name = localStorage.getItem('name');
  return <>{`${name}`}</>;
}

const allStaffRoles = Object.values(StaffUserRoles);

const routes = [
  {
    type: "collapse",
    name: <UserName />,
    key: "brooklyn-alice",
    icon: <MDAvatar src="/broken-image.jpg" size="sm" />,
    collapse: [
      // {
      //   name: "My Profile",
      //   key: "my-profile",
      //   route: "/pages/profile/profile-overview",
      //   // component: <ProfileOverview />,
      // },
      // {
      //   name: "Settings",
      //   key: "profile-settings",
      //   route: "/pages/account/settings",
      //   // component: <Settings />,
      // },
      {
        name: "Logout",
        key: "logout",
        path: "/auth/logout",
        route: "/auth/logout",
        component: <Logout />,
        isPublic: true,
      },
    ],
  },
  { type: "divider", key: "divider-2" },
  {
    type: "collapse", // collapse | title | divider
    name: "Dashboard",
    key: "dashboard",
    icon: <AnalyticsOutlinedIcon />,
    allowedRoles: [
      StaffUserRoles.admin, StaffUserRoles.management,
      StaffUserRoles.technicianManager, StaffUserRoles.scheduler,
      StaffUserRoles.finance,
      ClientRoles.scheduler,
    ],
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        path: "/dashboard/analytics",
        route: "/dashboard/analytics",
        allowedRoles: [
          StaffUserRoles.admin, StaffUserRoles.management,
          StaffUserRoles.technicianManager, StaffUserRoles.scheduler,
          StaffUserRoles.finance,
          ClientRoles.scheduler,
        ],
        component: <Analytics />,
      },
      // {
      //   name: "Sales",
      //   key: "sales",
      //   path: "/dashboard/sales",
      //   // component: <Analytics/>,
      // },
    ],
  },
  {
    type: "collapse",
    name: "Scheduling",
    key: "scheduling",
    noCollapse: false,
    icon: <CalendarMonthOutlinedIcon />,
    allowedRoles: [...allStaffRoles, ClientRoles.scheduler],
    collapse: [
      {
        type: "collapse",
        name: "FFS",
        key: "FFS",
        path: "/scheduling/FFS",
        route: "/scheduling/FFS",
        allowedRoles: [...allStaffRoles, ClientRoles.scheduler],
        component: <FFS />,
      },
      {
        type: "collapse",
        name: "IPT",
        key: "IPT",
        path: "/scheduling/IPT",
        route: "/scheduling/IPT",
        allowedRoles: [...allStaffRoles, ClientRoles.scheduler],
        component: <Scheduling key="ipt" classification={Classifications.IPT} />,
      },
      {
        type: "collapse",
        name: "CEET",
        key: "CEET",
        path: "/scheduling/CEET",
        route: "/scheduling/CEET",
        allowedRoles: [...allStaffRoles, ClientRoles.scheduler],
        component: <Scheduling key="ceet" classification={Classifications.CEET} />,
      },
      {
        type: "collapse",
        name: "Facility",
        key: "facility",
        path: "/scheduling/facility",
        route: "/scheduling/facility",
        allowedRoles: [...allStaffRoles, ClientRoles.scheduler],
        component: <Scheduling key="facility" classification={Classifications.FACILITIES} />,
      },
    ]
  },
  {
    type: "collapse",
    name: "My Training",
    key: "training",
    path: "/training",
    route: "/training",
    icon: <SchoolIcon />,
    allowedRoles: [
      ClientRoles.crew, ClientRoles.instructor,
    ],
    collapse: [
      {
        type: "collapse",
        name: "Upcoming Training",
        key: "upcoming-training",
        path: "/training/upcoming-training",
        route: "/training/upcoming-training",
        allowedRoles: [
          ClientRoles.crew, ClientRoles.instructor,
        ],
        component: <UpcomingTraining />,
      },
      {
        type: "collapse",
        name: "Completed Training",
        key: "completed-training",
        path: "/training/completed-training",
        route: "/training/completed-training",
        allowedRoles: [
          ClientRoles.crew, ClientRoles.instructor,
        ],
        component: <CompletedTraining />,
      }
    ],
  },
  {
    type: "collapse",
    name: "Report",
    key: "report",
    path: "/report",
    route: "/report",
    icon: <FindInPageOutlinedIcon />,
    allowedRoles: [
      StaffUserRoles.admin, StaffUserRoles.management,
      StaffUserRoles.technicianManager, StaffUserRoles.scheduler,
      StaffUserRoles.finance,
      ClientRoles.scheduler,
    ],
    collapse: [
      {
        type: "collapse",
        name: "Device Report",
        key: "device-report",
        path: "/report/device-report/:classificationName",
        route: "/report/device-report/simulator",
        allowedRoles: [
          StaffUserRoles.admin, StaffUserRoles.management,
          StaffUserRoles.technicianManager, StaffUserRoles.scheduler,
          StaffUserRoles.finance,
          ClientRoles.scheduler,
        ],
        component: <DeviceReport />,
      },
      {
        type: "collapse",
        name: "Facility Report",
        key: "facility-report",
        path: "/report/facility-report/:facilityType",
        route: "/report/facility-report/classroom",
        allowedRoles: [
          StaffUserRoles.admin, StaffUserRoles.management,
          StaffUserRoles.technicianManager, StaffUserRoles.scheduler,
          StaffUserRoles.finance,
          ClientRoles.scheduler,
        ],
        component: <FacilityReport />,
      },
    ],
  },
  {
    type: "collapse", // collapse | title | divider
    name: "Administration",
    key: "administration",
    icon: <SettingsOutlinedIcon />,
    allowedRoles: [
      StaffUserRoles.admin, StaffUserRoles.management,
      StaffUserRoles.technicianManager, StaffUserRoles.scheduler,
      StaffUserRoles.finance,
      ClientRoles.scheduler,
    ],
    collapse: [
      {
        type: "collapse",
        name: "Client",
        key: "client",
        path: "/administration/client",
        route: "/administration/client",
        allowedRoles: [
          StaffUserRoles.admin, StaffUserRoles.management,
          StaffUserRoles.technicianManager, StaffUserRoles.scheduler,
          StaffUserRoles.finance,
          ClientRoles.scheduler,
        ],
        component: <Client />,
      },
      {
        name: "Staff",
        key: "staff",
        path: "/administration/staff/:staffRoleId",
        route: "/administration/staff/1",
        allowedRoles: [
          StaffUserRoles.admin,
        ],
        component: <Staff />,
      },
      {
        name: "Device",
        key: "device",
        path: "/administration/device/:deviceTypeId",
        route: "/administration/device/1",
        allowedRoles: [
          StaffUserRoles.admin, StaffUserRoles.management,
          StaffUserRoles.technicianManager, StaffUserRoles.scheduler,
          StaffUserRoles.finance,
        ],
        component: <Device />,
      },
      {
        name: "Facility",
        key: "facility",
        path: "/administration/facility",
        route: "/administration/facility",
        allowedRoles: [
          StaffUserRoles.admin, StaffUserRoles.management,
          StaffUserRoles.technicianManager, StaffUserRoles.scheduler,
          StaffUserRoles.finance,
        ],
        component: <Facility />,
      },
    ],
  },

  // --------- other ---------
  {
    key: "login",
    path: "/auth/login",
    component: <Login />,
    isPublic: true,
  },
  {
    key: "reset-password",
    path: "/auth/reset-password",
    component: <ResetPassword />,
    isPublic: true,
  },
  {
    key: "change-password",
    path: "/auth/change-password",
    component: <ChangePassword />,
    isPublic: true,
  },
  {
    key: "administration-client-new",
    path: "/administration/client/new",
    allowedRoles: [
      StaffUserRoles.admin, StaffUserRoles.scheduler,
    ],
    component: <NewEditClient />,
  },
  {
    key: "administration-client-edit",
    path: "/administration/client/:clientId/edit",
    allowedRoles: [
      StaffUserRoles.admin, StaffUserRoles.scheduler,
      ClientRoles.scheduler,
    ],
    component: <NewEditClient />,
  },
  {
    key: "administration-client-users",
    path: "/administration/client/:clientId/users",
    allowedRoles: [
      StaffUserRoles.admin, StaffUserRoles.management,
      StaffUserRoles.technicianManager, StaffUserRoles.scheduler,
      StaffUserRoles.finance,
      ClientRoles.scheduler,
    ],
    component: <ClientUsers />,
  },
  {
    key: "administration-client-new-user",
    path: "/administration/client/:clientId/new-user",
    allowedRoles: [
      StaffUserRoles.admin, StaffUserRoles.scheduler,
      ClientRoles.scheduler,
    ],
    component: <ClientNewEditUser />,
  },
  {
    key: "administration-client-edit-user",
    path: "/administration/client/:clientId/edit-user/:userId",
    allowedRoles: [
      StaffUserRoles.admin, StaffUserRoles.scheduler,
      ClientRoles.scheduler,
    ],
    component: <ClientNewEditUser />,
  },
  {
    key: "administration-client-view-user",
    path: "/administration/client/:clientId/view-user/:userId",
    allowedRoles: [
      StaffUserRoles.admin, StaffUserRoles.management,
      StaffUserRoles.technicianManager, StaffUserRoles.scheduler,
      StaffUserRoles.finance,
      ClientRoles.scheduler,
    ],
    component: <ClientViewUser />,
  },
  {
    key: "administration-client-training-types",
    path: "/administration/client/:clientId/training-types/:classification",
    route: "/administration/client/:clientId/training-types/FFS",
    allowedRoles: [
      StaffUserRoles.admin, StaffUserRoles.management,
      StaffUserRoles.technicianManager, StaffUserRoles.scheduler,
      StaffUserRoles.finance,
      ClientRoles.scheduler,
    ],
    component: <TrainingType />,
  },
  // {
  //   key: "administration-client-view",
  //   path: "/administration/client/view/:id",
  //   component: <ViewDevice/>,
  // },
  {
    key: "administration-device-new",
    path: "/administration/device/:deviceTypeId/new",
    allowedRoles: [
      StaffUserRoles.admin, StaffUserRoles.scheduler,
    ],
    component: <NewEditDevice />,
  },
  {
    key: "administration-device-edit",
    path: "/administration/device/:deviceTypeId/edit",
    allowedRoles: [
      StaffUserRoles.admin, StaffUserRoles.scheduler,
    ],
    component: <NewEditDevice />,
  },
  {
    key: "administration-device-view",
    path: "/administration/device/:deviceTypeId/view",
    allowedRoles: [
      StaffUserRoles.admin, StaffUserRoles.management,
      StaffUserRoles.technicianManager, StaffUserRoles.scheduler,
      StaffUserRoles.finance,
    ],
    component: <ViewDevice />,
  }
];


export default routes;

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQuery';
import { Client, ClientUser, TrainingType } from "types/client";
import { STATUS } from 'lib/constants';

export const CUSTOMER_API_REDUCER_KEY = 'customerApi';

type CamelToSnakeCase<S extends string> =
    S extends `${infer T}${infer U}` ?
    `${T extends Capitalize<T> ? "_" : ""}${Lowercase<T>}${CamelToSnakeCase<U>}` :
    S

// convert camelCase to snake_case
type CustomerDB = {
    [K in keyof Client as CamelToSnakeCase<K>]: Client[K];
};

interface GetCustomersResponseI {
    data: {
        count: number;
        rows: CustomerDB[];
    }
    success: boolean;
}

interface GetCustomerUserRequest {
    customerIds?: number[];
    roleIds: number[];
}

interface GetCustomerUsersResponseI {
    data: ClientUser[];
    success: boolean;
}

type TrainingTypeDB = {
    [K in keyof TrainingType as CamelToSnakeCase<K>]: TrainingType[K];
}


export const customerApi = createApi({
    reducerPath: CUSTOMER_API_REDUCER_KEY,
    keepUnusedDataFor: 60,
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Customer', 'CustomerUser', 'TrainingType'],
    endpoints: (builder) => ({
        getCustomerOptions: builder.query<{ id: number, name: string }[], void>(
            {
                query: () => ({
                    url: '/admin/customer/listing',
                    method: 'GET',
                }),
                transformResponse: (response: GetCustomersResponseI) => {
                    const { data, success } = response;
                    if (success) {
                        return data.rows.map((c: CustomerDB) => {
                            return {
                                id: c.id,
                                name: `${c.short_name} - (${c.full_name})`,
                            }
                        });
                    } else {
                        return [];
                    }
                },
            }
        ),
        getCustomerUserOptions: builder.query<{ id: number, name: string }[], GetCustomerUserRequest>(
            {
                query: (params) => ({
                    url: '/admin/user/getUsersByCustomerIdAndRoles',
                    method: 'POST',
                    body: {
                        ...params,
                        isActive: 1
                    }
                }),
                transformResponse: (response: GetCustomerUsersResponseI) => {
                    const { data, success } = response;
                    if (success) {
                        const d = data.map((c: ClientUser) => {
                            return {
                                id: c.userId,
                                name: `${c.lastName || ''} ${c.firstName || ''}`,
                            }
                        });
                        const sortData = d.slice();
                        sortData.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name));
                        return sortData;
                    } else {
                        return [];
                    }
                }
            }
        ),
        getCustomerById: builder.query<Client, { id: number }>(
            {
                query: (params) => ({
                    url: '/admin/customer/getCustomerById',
                    method: 'GET',
                    params
                }),
                transformResponse: (response: any) => {
                    const { data, success } = response;
                    if (success) {
                        return data;
                    } else {
                        return null;
                    }
                },
                providesTags: ['Customer'],
            }
        ),
        getCustomerTrainingTypes: builder.query<TrainingType[], { customerId: number, classification: string, options: boolean }>(
            {
                query: ({ customerId, classification }) => ({
                    url: '/admin/training/listing',
                    method: 'GET',
                    params: { customerId, classification, status: STATUS.ACTIVE }
                }),
                transformResponse: (response: any, meta, { options }) => {
                    const { data, success } = response;
                    if (success) {
                        if (options) {
                            return data.rows.map((t: TrainingTypeDB) => {
                                return { id: t.id, name: t.short_name };
                            });
                        } else {
                            // convert to camelCase key
                            return data.rows.map((t: TrainingTypeDB) => {
                                return {
                                    id: t.id,
                                    customerId: t.customer_id,
                                    shortName: t.short_name,
                                    fullName: t.full_name,
                                    classification: t.classification,
                                    trainingHour: t.training_hour,
                                    status: t.status,
                                    createdAt: t.created_at,
                                    updatedAt: t.updated_at,
                                };
                            });
                        }
                    } else {
                        return [];
                    }
                },
                providesTags: ['TrainingType']
            }
        ),
        upsertTrainingType: builder.mutation<{ data?: TrainingType, success: boolean, error?: string }, TrainingType>(
            {
                query: (params) => ({
                    url: '/admin/training/upsert',
                    method: 'POST',
                    body: params
                }),
                transformResponse: (response: any) => {
                    const { data, success, errors } = response;
                    if (success) {
                        return { data, success };
                    } else {
                        throw new Error(errors[0]?.message);
                    }
                },
                invalidatesTags: ['TrainingType']
            }
        ),
    }),
});

export const STATUS_COLORS: Record<string, string> = {
    'SCHEDULED': 'white', // light blue
    'IN-PROGRESS': 'yellow', // orange
    'FULLY COMPLETED': '#A5A5A5', // gray
    'COMPLETED': '#A5A5A5', // gray
    'PARTIALLY COMPLETED': '#A5A5A5', // gray
    'AOG - CHANGED SIM': 'red', // red
    'AOG - CANCELED': 'red', // red
    'NO-SHOW': 'red', // red
}

export const Classifications = {
    FFS: "FFS",
    IPT: "IPT",
    CEET: "CEET",
    FACILITIES: "FACILITIES"
}

export const DEVICE_BOOKING_STATUS = [
    { id: 1, name: 'SCHEDULED' },
    { id: 2, name: 'IN-PROGRESS' },
    { id: 6, name: 'FULLY COMPLETED' },
    { id: 7, name: 'PARTIALLY COMPLETED' },
    { id: 3, name: 'AOG - CHANGED SIM' },
    { id: 5, name: 'AOG - CANCELED' },
    { id: 4, name: 'NO-SHOW' }
]

export const FACILITY_BOOKING_STATUS = [
    { id: 1, name: 'SCHEDULED' },
    { id: 2, name: 'IN-PROGRESS' },
    { id: 6, name: 'COMPLETED' },
    { id: 4, name: 'NO-SHOW' }
]

export const STATUS = {
    "ACTIVE": 1,
    "INACTIVE": 2
}
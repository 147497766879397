import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// console.log("1266");
export interface Device {
  aircraft_type? :string
  created_at? :string
  current_status? :number
  date_of_service? :string
  device_no? :string
  features_and_capabilities? :string
  id? :string
  model? :string
  modules? :string
  purpose? :string
  qualification_standards? :string

  brand? :string
  deviceName? :string
  classification? :string
  type? :string
}

const initialState = {
//  loading: false,
 devices: [] as any[],
//  error: '',
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setDevices: (state, action: PayloadAction<any[]>) => {
      state.devices = action.payload;
    }
  }
});

export const { setDevices } = deviceSlice.actions;
export default deviceSlice.reducer;




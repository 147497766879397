/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ChangeEvent, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "page/Auth/BasicLayout";

// Images
import bgImage from "assets/images/loginbg.webp";
import logoImg from "assets/images/haite-logo-light.png";
import { useNavigate } from "react-router-dom";
import PageLayout from "components/LayoutContainers/PageLayout";



interface Props {
}


const PageNotFound: React.FC<Props> = (): JSX.Element => {

  return (
    <PageLayout>
      <MDBox mt={"10%"} textAlign={"center"}>
        404. That’s an error. <br/>
        The requested URL was not found on this server.
      </MDBox>
    </PageLayout>
  );
}

export default PageNotFound;

import CONFIG from "lib/config";
import axios, { AxiosError, AxiosResponse } from "axios";
import CryptoJS from "crypto-js";
// import { Dispatch } from 'redux';
import axiosInstance from "lib/AxiosConfig";
import { resolve } from "path";


const sessionExpiration = 2 * 60 * 60 * 1000;
const jwtTokenExpiration = 1.5 * 60 * 60 * 1000;

// get password token
export const encryptPassword = (_password: string) => {

  const password = CryptoJS.AES.encrypt(JSON.stringify(_password), CONFIG.aesSecret).toString();

  const COMMON_TIMESTAMP = new Date().toISOString();
  const COMMON_SIGN = CryptoJS.MD5(
    `timestamp=${COMMON_TIMESTAMP}&key=C3BD24CC6D6CD9E02B2E6C96F0A23A62`
  ).toString(CryptoJS.enc.Hex).toUpperCase();


  return axiosInstance.post(
    `/user/getEncryptPwdAndEmailToken?timestamp=${COMMON_TIMESTAMP}&sign=${COMMON_SIGN}`,
    { password: password }
  )

}

// email also
const getUserNameToken = (username: string) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(username),
    CONFIG.aesSecret
  ).toString();
}

export const resetPasswordRequest = (username: string) => {
  return axiosInstance.post(
    "user/password/requestResetPassword",
    { email: getUserNameToken(username) }
  ).then((res) => {
    console.log('0148 resetPasswordRequest', res);
    return new Promise<void | any[]>((resolve, reject) => {
      if (res.status === 200 && res.data.success) {
        resolve();
      } else {
        console.error('0148 error resetPasswordRequest', res);
        reject(res.data?.errors);
      }
    });
  })
}

export const submitResetPassword = (body: any) => {
  return axiosInstance.post(
    "/user/password/submitResetPassword",
    body
  ).then((res) => {
    console.log('0148 submitResetPassword', res);
    return new Promise<void | any[]>((resolve, reject) => {
      if (res.status === 200 && res.data.success) {
        resolve();
      } else {
        console.error('0148 submitResetPassword error', res);
        reject(res.data?.errors);
      }
    });
  })
  // .catch((err)=>{
  //   console.error('0148 submitResetPassword catch', err)
  //   return '0148 submitResetPassword catch';
  // });
}

export const loginRequest = (username: string, password: string) => {
  console.log('0148 loginRequest username', username);
  // console.log('0148 loginRequest password', password);

  const userName = getUserNameToken(username);

  const apiURL = "/user/login";

  return encryptPassword(password)
    .then((res: AxiosResponse) => {
      return axiosInstance.post(apiURL, {
        userName: userName,
        password: res.data.passwordToken
      })
        .then((res) => {
          return new Promise((resolve, reject) => {
            if (res.data.success) {
              console.log('0148 loginSuccess', res);
              localStorage.setItem('userName', userName);
              localStorage.setItem('token', res.data.data.token);
              localStorage.setItem('sessionToken', res.data.data.sessionToken);
              localStorage.setItem('sessionExpiresTime', res.data.data.sessionExpiresTime);
              localStorage.setItem('name', res.data.data.firstName + ' ' + res.data.data.lastName);
              const roles = JSON.stringify(res.data.data.roles.map((role: any) => role.id));
              localStorage.setItem('roles', roles);
              localStorage.setItem('customerId', res.data.data.customerId);
              getCustomerData().then((customerData) => {
                localStorage.setItem('customerShortName', customerData?.shortName);
                localStorage.setItem('customerFullName', customerData?.fullName);
                console.log('0148 loginSuccess getCustomerData', customerData);
                resolve(res.data.data);
              });
            } else {
              console.error('0148 loginFailure', res.data.errors[0].message);
              console.error('0148 loginFailure', res);
              reject(res.data.errors[0].message);
            }
          });
        });
    })
}

export const logoutRequest = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('sessionToken');
  localStorage.removeItem('sessionExpiresTime');
  localStorage.removeItem('userName');
  localStorage.removeItem('name');
  localStorage.removeItem('avatarUrl');
  localStorage.removeItem('roles');
  localStorage.removeItem('customerId');
}

export const getCustomerData = async () => {
  const customerId = localStorage.getItem('customerId');
  return axiosInstance.get('/admin/customer/getCustomerById', { params: { id: customerId } })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error('0148 getCustomerData', err);
      return null;
    });
}


import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Country, Device, Facility, CustomerType, SessionSlotsI } from "types/common";
interface CommonState {
  isLoaded: boolean
  countries: Country[];
  devices: Device[];
  facilities: Facility[];
  customerTypes: CustomerType[];
  sessionSlot: SessionSlotsI;
}

const initialState: CommonState = {
  isLoaded: false,
  countries: [] as Country[],
  devices: [] as Device[],
  facilities: [] as Facility[],
  customerTypes: [] as CustomerType[],
  sessionSlot: {} as SessionSlotsI,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setProfileMasters: (state, action: PayloadAction<any>) => {
      state.countries = action.payload.countries;
      state.devices = action.payload.devices;
      state.facilities = action.payload.facilities;
      state.customerTypes = action.payload.customerTypes;
      state.sessionSlot = action.payload.sessionSlot;
      state.isLoaded = true;
      // console.log('0407 setProfileMasters state', state);
      // console.log('0407 setProfileMasters action', action);
    }
  },
});

export const { setProfileMasters } = commonSlice.actions;

export default commonSlice.reducer;

import axios from "axios";
import CONFIG from "lib/config";
import getApiSignatureAndTimestamp from "lib/utils/apiSignature";


export const getProfileMasters = () => {
  // console.log("7637 getProfileMasters");
  const {timestamp, signature} = getApiSignatureAndTimestamp();
  return axios
    .get(`${CONFIG.apiBaseUrl}/common/getprofilemasters?timestamp=${timestamp}&sign=${signature}`)
    .then((res: any)=>{
      // console.log("7637 getprofilemasters res", res);
      return new Promise((resolve, reject)=>{
        if(res.status === 200){
          resolve(res.data.data);
        }else{
          console.error("7637 getProfileMasters error");
          reject("getProfileMasters error");
        }
      });
    })
    // .catch((err)=>{
    //   console.error("7637 getprofilemasters res", err);
    // })
  ;

}
import axios from "axios";
import CONFIG from "./config";
import { useNavigate } from "react-router-dom";

// console.log("8900 axios.interceptors");

const axiosInstance = axios.create({
  baseURL: CONFIG.apiBaseUrl,
});

const refreshToken = async () => {
  const sessionToken = localStorage.getItem('sessionToken');
  const token = localStorage.getItem('userName'); // userName token
  // console.log("8900 sessionToken", sessionToken);
  // console.log("8900 userName token", token);
  if (sessionToken && token) {
    console.log("8900 request new JwtToken");
    await axios.post(`${CONFIG.apiBaseUrl}/user/requestJwtToken`, { sessionToken, token })
      .then((res) => {
        // console.log("8900 refresh token res", res);
        // console.log("8900 refresh token", res.data.data.token);
        // console.log("8900 refresh sessionToken", res.data.data.sessionToken);
        // console.log("8900 refresh sessionExpiresTime", res.data.data.sessionExpiresTime);
        localStorage.setItem('token', res.data.data.token);
        localStorage.setItem('sessionToken', res.data.data.sessionToken);
        localStorage.setItem('sessionExpiresTime', res.data.data.sessionExpiresTime);
      })
  } else {
    console.error("8900 no sessionToken or userName token");
  }
}

axiosInstance.interceptors.request.use(
  config => {
    console.log("8900 axios.interceptors.request", config.url);
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    // console.log("8900 axios.interceptors.request.error", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    // console.log("8900 axios.interceptors.response", response);
    return response;
  },
  async error => {
    // console.log("8900 axios.interceptors.response.error", error);
    const originalRequest = error.config;
    const sessionExpiresTime = parseInt(localStorage.getItem("sessionExpiresTime")) * 1000 || 0;
    const isSessionExpired = sessionExpiresTime < Date.now();

    if (error.response && error.response.status === 401
      && error.response.data.err.name === 'TokenExpiredError'
      && !isSessionExpired
      && !originalRequest.__retry) {
      await refreshToken();
      originalRequest.__retry = true;
      return axiosInstance(originalRequest);
    } else {
      console.error("8900 error originalRequest", originalRequest);
      console.error("8900 error isSessionExpired", isSessionExpired);
      // const navigate = useNavigate();
      // navigate("/auth/login");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./AuthReducer";
import deviceReducer from "./admin/DeviceReducer";
import clientsReducer from "./admin/ClientsReducer";
import commonReducer from "./CommonReducer";
import { CUSTOMER_API_REDUCER_KEY, customerApi } from "apis/Customer";
import { BOOKING_API_REDUCER_KEY, bookingApi } from "apis/Booking";
import { DASHBOARD_API_REDUCER_KEY, dashboardApi } from "apis/Dashboard";
import { REPORT_API_REDUCER_KEY, reportApi } from "apis/Report";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: combineReducers({
    auth: AuthReducer,
    device: deviceReducer,
    client: clientsReducer,
    common: commonReducer,
    [CUSTOMER_API_REDUCER_KEY]: customerApi.reducer,
    [BOOKING_API_REDUCER_KEY]: bookingApi.reducer,
    [DASHBOARD_API_REDUCER_KEY]: dashboardApi.reducer,
    [REPORT_API_REDUCER_KEY]: reportApi.reducer,
  }),
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware()
    .concat(customerApi.middleware)
    .concat(bookingApi.middleware)
    .concat(dashboardApi.middleware)
    .concat(reportApi.middleware),
  // middleware: [thunk]
});

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
export default store;

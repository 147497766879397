/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, JSXElementConstructor, Key, ReactElement } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS exampless
import Sidenav from "components/Sidenav";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React TS routes
import routes from "routes";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/haite-logo-favicon-WHITE.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { useDispatch, useSelector } from "react-redux";
import React from "react";

// 
import PageNotFound from "page/Auth/PageNotFound";
import { logoutRequest } from "apis/Auth";
import { getProfileMasters } from "apis/Common";
import { setProfileMasters } from "reducers/CommonReducer";
import { RootState } from "reducers/store";
import { parseJwt } from "lib/utils/jwt";
import ProtectedRoute from "components/ProtectedRoute";
import dayjs from "dayjs";

interface Props {

}

const App: React.FC<Props> = ({ }): JSX.Element => {

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  let publicRoute: string[] = [];

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        path: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
        isPublic: boolean;
        allowedRoles: number[];
      }) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }
        if (route.path) {
          if (route.isPublic) {
            publicRoute.push(route.path.split('?')[0]);
          }
          return <Route path={route.path} element={
            route.isPublic ? route.component : <ProtectedRoute allowedRoles={route.allowedRoles}>{route.component}</ProtectedRoute>
          } key={route.key} />;
        }
        return null;
      }
    )
    ;

  const configsButton = (
    <MDBox display="flex" justifyContent="center" alignItems="center" width="3.25rem"
      height="3.25rem" bgColor="white" shadow="sm" borderRadius="50%" position="fixed"
      right="2rem" bottom="2rem" zIndex={99} color="dark" sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen} >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  const loading = () => (
    <div className="animated fadeIn pt-3 text-center">Loading...</div>
  );

  const navigate = useNavigate();

  const sessionExpiresTime = parseInt(localStorage.getItem("sessionExpiresTime")) * 1000 || 0;

  const dispatch2 = useDispatch();
  const isProfileMastersLoaded = useSelector((state: RootState) => state.common.isLoaded);

  useEffect(() => {
    if (!isProfileMastersLoaded) {
      getProfileMasters().then((res: any) => {
        dispatch2(setProfileMasters(res));
      }).catch((err) => {
        console.error("0000 getProfileMasters error", err);
      });
    }
    // console.log("0000 isProfileMastersLoaded", isProfileMastersLoaded);
  }, [isProfileMastersLoaded]);

  // const publicRoute = ;

  useEffect(
    () => {
      if (sessionExpiresTime) {
        const loginUrl = "/auth/login";
        // 4 hours 15 mins
        const checkTime = (4 * 60 * 60 * 1000) + (15 * 60 * 1000);
        console.log("0000 sessionExpiresTime", sessionExpiresTime);
        console.log("0000 checkTime", checkTime);
        setTimeout(
          () => {
            const decoded = parseJwt(localStorage.getItem("token"));
            if (!decoded || (decoded && dayjs.unix(decoded.exp).isBefore(dayjs()))) {
              logoutRequest();
              setTimeout(() => navigate(loginUrl), 10);
            }
          },
          checkTime
        );
      }
    },
    [sessionExpiresTime]
  );

  useEffect(() => {
    console.log("sessionExpiresTime", sessionExpiresTime)
    const isAuthorized = sessionExpiresTime > Date.now() ? true : false;
    const loginUrl = "/auth/login";
    const isLoginPage = pathname === loginUrl ? true : false;
    const isPublicPage = publicRoute.includes(pathname);

    // console.log("0000 navigate('/dashboard/analytics');");
    if (isAuthorized && isLoginPage) {
      console.log("0000 navigate('/dashboard/analytics');");
      navigate('/dashboard/analytics');
    } else if (!isAuthorized && !isLoginPage && !isPublicPage) {
      console.log("0000 !isAuthorized && !isLoginPage && !isPublicPage", pathname, 'isAuthorized', isAuthorized, 'isLoginPage', isLoginPage, 'isPublicPage', isPublicPage);
      logoutRequest();
      setTimeout(() => navigate(loginUrl), 10);
    } else {
      console.log("0000 else pathname", pathname, 'isAuthorized', isAuthorized, 'isLoginPage', isLoginPage, 'isPublicPage', isPublicPage);
    }
    console.log("0000 publicRoute", publicRoute);

  }, [pathname]);


  const renderApp = () => {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Scheduling"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            {/* <Configurator /> */}
            {/* {configsButton} */}
          </>
        )}
        {/* {layout === "vr" && <Configurator />} */}
        <Routes>
          {getRoutes(routes)}
          {/* <Route path="*" element={<Navigate to="/auth/login" />} /> */}
          <Route path="/" element={<Navigate to="/dashboard/analytics" />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ThemeProvider>
    );
  }

  return (
    <React.Suspense fallback={loading()}>
      {renderApp()}
    </React.Suspense>
  );
}


export default App;
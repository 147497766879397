import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from "@reduxjs/toolkit/query"
import { Mutex } from 'async-mutex'

const BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://api.tcms-dev.telsma.com";
// create a new mutex
const mutex = new Mutex()
const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
})

interface RefreshTokenResponse {
    data: {
        sessionExpiresTime: number
        sessionToken: string
        token: string
    }
}

const baseQueryWithAuth = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token')
        // If we have a token set in state, let's assume that we should be passing it.
        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },
})

const loginPage = '/auth/login';

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock()
    let result = await baseQueryWithAuth(args, api, extraOptions)
    // console.log("result", result);
    if (result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()
            try {
                // post method
                const sessionToken = localStorage.getItem('sessionToken');
                const token = localStorage.getItem('userName'); // userName token
                console.log("refresh token", sessionToken);
                if (!token || !sessionToken) {
                    // if we don't have a token, there's no way to refresh it
                    // so redirect to login
                    window.location.href = loginPage;
                }
                const refreshResult = await baseQuery(
                    {
                        url: '/user/requestJwtToken',
                        method: 'POST',
                        body: {
                            token,
                            sessionToken
                        }
                    },
                    api,
                    extraOptions
                ) as { data: RefreshTokenResponse, meta?: { request: Request; response: Response } };
                console.log("refreshResult", refreshResult);
                if (refreshResult.data && refreshResult.data.data) {
                    const res = refreshResult.data.data;
                    console.log("res", res);
                    localStorage.setItem('token', res.token);
                    localStorage.setItem('sessionToken', res.sessionToken);
                    localStorage.setItem('sessionExpiresTime', String(res.sessionExpiresTime));
                    // retry the initial query now that we have a new token
                    result = await baseQueryWithAuth(args, api, extraOptions)
                } else {
                    // if the refresh request fails, we want to log the user out
                    localStorage.removeItem('token')
                    localStorage.removeItem('sessionToken')
                    localStorage.removeItem('sessionExpiresTime')
                    // redirect to login page
                    window.location.href = loginPage;
                }
            } finally {
                // release must be called once the mutex should be released again.
                release()
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock()
            result = await baseQueryWithAuth(args, api, extraOptions)
        }
    }
    return result
}
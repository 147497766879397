import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Client } from 'types/client';
import { User } from 'types/user';

const initialState = {
 clients: [] as Client[],
 client: {} as Client,
 users: {} as User[]
};

const clientsSlice = createSlice({
 name: 'clients',
 initialState,
 reducers: {
    setClients: (state, action: PayloadAction<Client[]>) => {
      state.clients = action.payload;
    },
    setClient: (state, action: PayloadAction<Client>) => {
      state.client = action.payload;
    },
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
    },
 },
});

export const { 
  setClient, 
  setClients, 
} = clientsSlice.actions;

export default clientsSlice.reducer;
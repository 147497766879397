
export type Client = {
  id?: number;
  fullName?: string;
  shortName?: string;
  country?: string;
  abbrCode?: string
  color?: string
  contractEndDate?: string
  contractStartDate?: string
  countryCode?: string
  typeId?: number | string
  email?: string
  mainContactPerson?: string
  phoneNumber?: string
  status?: number
  createdAt?: string
  updatedAt?: string
  devices?: {
    deviceId: number
    deviceName: string
    salesPrice: string
    selfBooking: boolean
  }[]
  facilities?: any[]
  training?: any[]
}

// export enum  ClientStatus {'Active', 'Suspended', 'Done', 'Good'};

export type ClientUser = {
  id?: number
  userId?: number
  firstName?: string
  lastName?: string
  email?: string
  userName?: string
  createdAt?: number | string // timestamp
  updatedAt?: number | string // timestamp
  phoneNumber?: string | number
  status?: number
  password?: string
  repeatPassword?: string
  countryCode?: string
  gender?: string
  clientId?: number | string
  isActive?: number // 1 active, 2 inactive
  isReactivated?: number
  signUpOptions?: number
  roles?: any[]
}

export enum ClientUserStatus { 'Active', 'Suspended', 'Deleted' };
export enum ClientUserRole { 'Scheduler', 'Instructor', 'Engineer', 'Finance', 'Management' };

export type TrainingType = {
  id?: number;
  fullName?: string;
  shortName?: string;
  customerId?: number | string;
  classification?: string;
  createdAt?: string;
  updatedAt?: string;
  trainingHour?: number;
  status?: number;
}

export const StaffUserRoles = {
  "admin": 1,
  "management": 2,
  "technicianManager": 3,
  "scheduler": 4,
  "technician": 5,
  "finance": 6,
  "technicianViewer": 10
}

export const ClientRoles = {
  "scheduler": 7,
  "instructor": 8,
  "crew": 9,
}

export const RoleOptions = [
  { id: -1, name: 'User' },
  { id: 1, name: 'Admin' },
  { id: 2, name: 'Management' },
  { id: 3, name: 'Technician Manager' },
  { id: 4, name: 'Scheduler' },
  { id: 5, name: 'Technician' },
  { id: 6, name: 'Finance' },
  { id: 7, name: 'Scheduler' },
  { id: 8, name: 'Instructor' },
  { id: 9, name: 'Crew' },
  { id: 10, name: 'Technician Viewer' },
]

export const ClientUserRoles = [
  RoleOptions.find((value) => value.id === 7),
  RoleOptions.find((value) => value.id === 8),
  RoleOptions.find((value) => value.id === 9),
]

export const StaffRoles = [
  RoleOptions.find((value) => value.id === 1),
  RoleOptions.find((value) => value.id === 2),
  RoleOptions.find((value) => value.id === 4),
  RoleOptions.find((value) => value.id === 3),
  RoleOptions.find((value) => value.id === 5),
  RoleOptions.find((value) => value.id === 6),
  RoleOptions.find((value) => value.id === 10),
]
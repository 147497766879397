
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQuery';

export const REPORT_API_REDUCER_KEY = 'reportApi';

export interface ReportRequestI {
    startTime?: string
    endTime?: string
    classification?: string
    deviceIds?: string
    facilityIds?: string
    simulatorType?: number
    clientIds?: string
    instructorId?: number
    crewId?: number

    pagesize?: number
    pagenumber?: number
    sort?: string
}

export interface ReportI {
    bookingId: number;
    bookingRunningNumber: string;
    deviceName?: string;
    simulatorType?: string;
    facilityName?: string;
    date: string;
    weekDayName: string;
    time: string;
    hours: number;
    clientName: string;
    instructorName: string;
}

export interface ReportResponseI {
    count: number;
    totalHours: number;
    rows: ReportI[];
}

export interface TrainingI {
    bookingId: number;
    deviceName?: string;
    date: string;
    weekDayName?: string;
    time: string;
    todayOrTomorrow: string;
    typeOfTraining: string;
    instructors: { id: number, name: string }[];
    crews: { id: number, name: string }[];
}

export interface UpcomingTrainingResponseI {
    count: number;
    totalHours: number;
    rows: TrainingI[];
    upcomingSimulatorTrainingCount: number;
    upcomingIPTTrainingCount: number;
    upcomingCEETTrainingCount: number;
    upcomingFACILITIESTrainingCount: number;
    upcomingSimulatorTrainingInCurrentWeekCount: number;
    upcomingIPTTrainingInCurrentWeekCount: number;
    upcomingCEETTrainingInCurrentWeekCount: number;
    upcomingFACILITIESTrainingInCurrentWeekCount: number;
}
export interface CompletedTrainingResponseI {
    count: number;
    totalHours: number;
    rows: TrainingI[];
    completedSimulatorTrainingCount: number;
    completedIPTTrainingCount: number;
    completedCEETTrainingCount: number;
    completedFACILITIESTrainingCount: number;
    completedSimulatorTrainingInCurrentWeekCount: number;
    completedIPTTrainingInCurrentWeekCount: number;
    completedCEETTrainingInCurrentWeekCount: number;
    completedFACILITIESTrainingInCurrentWeekCount: number;
}

export const reportApi = createApi({
    reducerPath: REPORT_API_REDUCER_KEY,
    keepUnusedDataFor: 60,
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getReport: builder.query<ReportResponseI, ReportRequestI>(
            {
                query: (params) => ({
                    url: '/admin/report',
                    method: 'GET',
                    params
                }),
                transformResponse: (response: any, meta, arg) => {
                    // console.log("arg:", arg);
                    const { data, success } = response;
                    if (success && data && data.rows) {
                        // console.log("data:", data)
                        return data;
                    }
                    return {
                        count: 0,
                        totalHours: 0,
                        rows: [],
                    }
                },
            }
        ),
        getUpcomingTraining: builder.query<UpcomingTrainingResponseI, ReportRequestI>(
            {
                query: (params) => ({
                    url: '/admin/upcomingTraining',
                    method: 'GET',
                    params
                }),
                transformResponse: (response: any, meta, arg) => {
                    // console.log("arg:", arg);
                    const { data, success } = response;
                    if (success && data && data.rows) {
                        // console.log("data:", data)
                        return data;
                    }
                    return {
                        count: 0,
                        totalHours: 0,
                        rows: [],
                    }
                },
            }
        ),
        getCompletedTraining: builder.query<CompletedTrainingResponseI, ReportRequestI>(
            {
                query: (params) => ({
                    url: '/admin/completedTraining',
                    method: 'GET',
                    params
                }),
                transformResponse: (response: any, meta, arg) => {
                    // console.log("arg:", arg);
                    const { data, success } = response;
                    if (success && data && data.rows) {
                        // console.log("data:", data)
                        return data;
                    }
                    return {
                        count: 0,
                        totalHours: 0,
                        rows: [],
                    }
                },
            }
        ),
    }),
});
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AuthState {
  isAuthenticated: boolean;
  user: any;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    doLogin: (state, action: PayloadAction<any>) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    doLogout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

// export const { doLogin, doLogout } = authSlice.actions;

export default authSlice.reducer;

import CONFIG from "lib/config";
import CryptoJS from "crypto-js";

const getApiSignatureAndTimestamp = () => {
  let date = new Date();
  let timestamp = date.toISOString();

  const key = CONFIG.apiSecretKey;
  const tempString = "timestamp=" + timestamp + "&key=" + key;
  const signature = CryptoJS.MD5(tempString)
    .toString(CryptoJS.enc.Hex)
    .toUpperCase();

  return { timestamp, signature };
};

export default getApiSignatureAndTimestamp;

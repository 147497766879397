import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: JSX.Element;
  allowedRoles: number[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  allowedRoles = [],
}) => {
  const userRoles = localStorage.getItem("roles") ? JSON.parse(localStorage.getItem("roles") as string) : [];
  const isAuthorized = allowedRoles.some((role) => userRoles?.includes(role));

  if (!userRoles || !isAuthorized) {
    return <Navigate to="/auth/login" />;
  }
  return children;
};

export default ProtectedRoute;